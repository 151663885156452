import React, { useState, useEffect } from "react"
import { useIntl } from "react-intl"

const MailingOptions = ({ onOptionsChange }) => {

  const intl = useIntl()

  const [ cityRes, setCityRes ] = useState(true)
  const [ cityApt, setCityApt ] = useState(true)
  const [ cityBiz, setCityBiz ] = useState(true)

  const [ poRes, setPoRes ] = useState(true)
  const [ poBiz, setPoBiz ] = useState(true)

  const [ ruralRes, setRuralRes ] = useState(true)
  const [ ruralApt, setRuralApt ] = useState(true)
  const [ ruralBiz, setRuralBiz ] = useState(true)

  const [ showOneOptionRequired, setShowOneOptionRequired ] = useState(false)

  useEffect(() => {
    const options = []

    if (cityRes) options.push('cityres')
    if (cityApt) options.push('cityapt')
    if (cityBiz) options.push('citybiz')

    if (poRes) options.push('pores')
    if (poBiz) options.push('pobiz')

    if (ruralRes) options.push('ruralres')
    if (ruralApt) options.push('ruralapt')
    if (ruralBiz) options.push('ruralbiz')

    setShowOneOptionRequired(!options.length)
    onOptionsChange(options)
  }, [onOptionsChange, cityRes, cityApt, cityBiz, poRes, poBiz, ruralRes, ruralApt, ruralBiz])
  
  return (
    <>
      
      
      <table className="table is-narrow is-fullwidth is-bordered is-size-7">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: "mailing_options.city_routes" })}</th>
            <th>{intl.formatMessage({ id: "mailing_options.po_boxes" })}</th>
            <th>{intl.formatMessage({ id: "mailing_options.rural_routes" })}</th>
          </tr>
        </thead>
        <tbody>
          {showOneOptionRequired &&
            <tr>
              <td colSpan="3" className="has-text-centered">
                <p className="help is-danger">
                  {intl.formatMessage({ id: "mailing_options.one_option" })}
                </p>
              </td>
            </tr>
          }
          <tr>
            <td>
              <ul>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" checked={cityRes} onChange={e=>setCityRes(e.target.checked)} /> 
                    {intl.formatMessage({ id: "mailing_options.residential" })}
                  </label>
                </li>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" checked={cityApt} onChange={e=>setCityApt(e.target.checked)} /> 
                    {intl.formatMessage({ id: "mailing_options.apartments" })}
                  </label>
                </li>
                <li>
                <label className="checkbox">
                  <input type="checkbox" checked={cityBiz} onChange={e=>setCityBiz(e.target.checked)} /> 
                  {intl.formatMessage({ id: "mailing_options.business" })}
                </label>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" checked={poRes} onChange={e=>setPoRes(e.target.checked)} /> 
                    {intl.formatMessage({ id: "mailing_options.residential" })}
                  </label>
                </li>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" checked={poBiz} onChange={e=>setPoBiz(e.target.checked)} /> 
                    {intl.formatMessage({ id: "mailing_options.business" })}
                  </label>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" checked={ruralRes} onChange={e=>setRuralRes(e.target.checked)} /> 
                    {intl.formatMessage({ id: "mailing_options.residential" })}
                  </label>
                </li>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" checked={ruralApt} onChange={e=>setRuralApt(e.target.checked)} /> 
                    {intl.formatMessage({ id: "mailing_options.apartments" })}
                  </label>
                </li>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" checked={ruralBiz} onChange={e=>setRuralBiz(e.target.checked)} /> 
                    {intl.formatMessage({ id: "mailing_options.business" })}
                  </label>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default MailingOptions