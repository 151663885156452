import React, { useEffect, useState, useContext, useRef } from "react"
import { graphql } from "gatsby"
import { useLazyQuery, gql } from "@apollo/client"
import { useIntl } from "react-intl"

import { IntlContext } from "../../../intl"
import Layout from "../../layout"
import SEO from "../../seo"
import EmailCaptureModal from "../../email-capture-modal"
import MailingOptions from "./mailing-options"

// TODO: review https://janosh.dev/blog/google-maps+react-hooks 

const GET_COUNT_BY_MILE_RADIUS = gql`
  query listCountByMileRadius($input: CountByMileRadiusInput!) {
    listCountByMileRadius(input: $input) {
      totalCount
      nameCount
    }
  }
`

export const query = graphql`
	query($id: String!) {
		prismicMapMyCustomers(id: { eq: $id }) {
      uid
      url
      lang
			data {
				title {
					text
				}
				description {
					text
				}
        address_error_message
        starting_address_label
        starting_address_placeholder
        radius_label
        radius_miles
        map_button_text
        records_found_message
			}
      alternate_languages {
        lang
        url
      }
		}
	}
`

const MapMyCustomers = ({ location, data }) => {

  const intl = useIntl()
  const modalTriggerRef1 = useRef()
  const modalTriggerRef2 = useRef()

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicMapMyCustomers.lang);
    setAlts(data.prismicMapMyCustomers.alternate_languages || [])
  }, [changeLocale, setAlts, data])

  const [address, setAddress] = useState((location.state && location.state.address) || "")
  const [radius, setRadius] = useState(0.5)
  const [mailingOptions, setMailingOptions] = useState([])
  const [showMailingOptions, setShowMailingOptions] = useState(false)

  const [map, setMap] = useState(null)
  const [innerCircle, setInnerCircle] = useState(null)
  const [outerCircle, setOuterCircle] = useState(null)

  const [count, setCount] = useState(null)

  const [showErrorAddress, setShowErrorAddress] = useState(false)

  const mapBoxStyle = {
    background: "#f2f3f4",
    padding: "0",
    margin: "0",
    height: "70vh",
    width: "100%",
    minHeight: "320px"
  }

  const hideErrorAddress = () => setShowErrorAddress(false)
  const handleAddressChange = (e) =>  setAddress(e.target.value)
  const handleRadiusChange = (e) => setRadius(e.target.value)
  const toggleMailingOptions = () => setShowMailingOptions(!showMailingOptions)

  const mapRadius = (e) => {
    e.preventDefault();
    setShowErrorAddress(false);
    setCount(null);

    let url = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?singleLine="+
      encodeURIComponent(address)+"&maxLocations=1&category=Street%20Address&outFields=StAddr%2CPostal&f=pjson";
    let req = new XMLHttpRequest();
    req.onreadystatechange = function() {
      if (req.readyState === 4) {
        if (req.status !== 200) return setShowErrorAddress(true)

        const candidates = JSON.parse(req.response).candidates;
        if (candidates.length > 0) {
          const addressData = candidates[0]
          setAddress(addressData.address);

          // drawPointAndRadius
          if (outerCircle) outerCircle.setMap(null)
          if (innerCircle) innerCircle.setMap(null)
          let zoom = 15
          if (radius > 4) zoom = 11
          if (radius > 3) zoom = 12
          else if (radius > 2) zoom = 12
          else if (radius > 1) zoom = 13
          else if (radius > 0.5) zoom = 14
          map.panTo({ lat: addressData.location.y, lng: addressData.location.x });
          map.setZoom(zoom)
          setOuterCircle(new window.google.maps.Circle({
            strokeColor: "#00c4a7",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#00c4a7",
            fillOpacity: 0.35,
            map,
            center: { lat: addressData.location.y, lng: addressData.location.x },
            radius: radius*1609.344,
            clickable: false,
          }));
          setInnerCircle(new window.google.maps.Circle({
            strokeColor: "#00c4a7",
            strokeOpacity: 0.8,
            strokeWeight: 5,
            fillColor: "#00c4a7",
            fillOpacity: 0.8,
            map,
            center: { lat: addressData.location.y, lng: addressData.location.x },
            radius: 5,
            clickable: false,
          }));

          // call getCount
          let input = {
            address: addressData.attributes.StAddr,
            zip: addressData.attributes.Postal,
            mile: parseFloat(parseFloat(radius).toFixed(3)),
            options: {
              cityres: mailingOptions.indexOf('cityres') > -1,
              cityapt: mailingOptions.indexOf('cityapt') > -1,
              citybiz: mailingOptions.indexOf('citybiz') > -1,
              pores: mailingOptions.indexOf('pores') > -1,
              pobiz: mailingOptions.indexOf('pobiz') > -1,
              ruralres: mailingOptions.indexOf('ruralres') > -1,
              ruralapt: mailingOptions.indexOf('ruralapt') > -1,
              ruralbiz: mailingOptions.indexOf('ruralbiz') > -1
            }
          };

          // for (let opt of mailingOptions) {
          //   input.options[opt] = true
          // }

          getCount({ variables: {input} })
        } else {
          // could not match address
          setShowErrorAddress(true)
        }
      }
    }
    req.open("GET", url, true); // true for asynchronous 
    req.send(null);
  }

  // get count by mile radius
  const [getCount, {loading: loadingGetCount, /*error: errorGetCount*/}] = useLazyQuery(GET_COUNT_BY_MILE_RADIUS, {
    onCompleted: (data) => {
      setCount(data.listCountByMileRadius.totalCount);
    },
    onError: (error) => {
      // TODO: log error
      // TODO: show error count
      // TODO: fix apollo to throw error after too many attempts or service unreachable!
      console.log(error);
    }
  })

  useEffect(() => {
    const onLoad = () => setMap(new window.google.maps.Map(document.getElementById('mapBox'), {
      center: { lat: 37, lng: -95 },
      zoom: 3.9,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      styles: [
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
      ]
    }))
    if (!window.google) {
      const script = document.createElement(`script`)
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=places`
      document.head.append(script)
      script.addEventListener(`load`, onLoad)
      return () => script.removeEventListener(`load`, onLoad)
    } else onLoad()
  }, [setMap])

  return (
    <Layout>
      <SEO 
        lang={data.prismicMapMyCustomers.lang} 
        title={data.prismicMapMyCustomers.data.title.text} 
        meta={[{
          "http-equiv": "content-language", 
          content: data.prismicMapMyCustomers.lang
        }]} 
        links={data.prismicMapMyCustomers.alternate_languages.concat([data.prismicMapMyCustomers]).map(alt => ({
          rel: "alternate", 
          hreflang: alt.lang, 
          href: `https://www.customerradius.com${alt.url}`
        })).concat([{
          rel: "alternate", 
          hreflang: "x-default", 
          href: "https://www.customerradius.com/map-my-customers"
        }])}/>
      <section className="section">
        <div className="is-pulled-right is-hidden-touch">
          <button className="button is-warning is-large" ref={modalTriggerRef1}>
            {intl.formatMessage({ id: "email_capture.request_early_access" })}
          </button>
          <EmailCaptureModal triggerRef={modalTriggerRef1} intl={intl} sourceDetail={'Map My Customers'} />
        </div>
        <h1 className="title">{data.prismicMapMyCustomers.data.title.text}</h1>
        <h2 className="subtitle has-text-grey">
          {data.prismicMapMyCustomers.data.description.text}
        </h2>
      </section>
      <section className="section">
        <div className="columns">
          <div className="column">
            <form onSubmit={mapRadius}>
              {showErrorAddress &&
                <div className="notification is-danger">
                  <button className="delete" onClick={hideErrorAddress}>close</button>
                  {data.prismicMapMyCustomers.data.address_error_message}
                </div>
              }
              <div className="field" style={{display:"block"}}>
                <div className="control">
                  <input className="input is-rounded is-primary is-large" type="text" 
                    placeholder={data.prismicMapMyCustomers.data.starting_address_placeholder}
                    value={address}
                    onChange={handleAddressChange} required />
                </div>
              </div>
              <div className="field has-addons">
                <div className="control">
                  <input className="input is-rounded is-primary" 
                    type="number" min="0.05" max="5" step="0.01"
                    placeholder={data.prismicMapMyCustomers.data.radius_placeholder}
                    value={radius}
                    onChange={handleRadiusChange} required />
                </div>
                <div className="control">
                  <span className="button is-primary is-rounded is-static" style={{borderColor:"#00d1b2"}}>
                    {data.prismicMapMyCustomers.data.radius_miles}
                  </span>
                </div>
              </div>
              <div className="field">
                <button className="button is-text is-small" onClick={toggleMailingOptions} type="button">
                  {showMailingOptions 
                    ? <span>{intl.formatMessage({ id: "mailing_options.hide" })}</span> 
                    : <span>{intl.formatMessage({ id: "mailing_options.show" })}</span>}
                </button>
              </div>
              <div className={showMailingOptions?"my-2":"my-2 is-hidden"}>
                <MailingOptions onOptionsChange={setMailingOptions} />
              </div>
              <button className={loadingGetCount?"button is-primary is-rounded is-medium is-loading":"button is-primary is-rounded is-medium"} type="submit">
                {data.prismicMapMyCustomers.data.map_button_text}
              </button>
            </form>
            {count && 
              <>
                <div className="box my-5 notification is-success is-light is-size-4">
                  <span className="has-text-weight-bold">{count}</span> 
                  {` `}
                  {data.prismicMapMyCustomers.data.records_found_message}
                </div>
                <div className="">
                  {/* <p>Get your CustomerRadius results emailed to you.</p> */}
                </div>
              </>
            }
          </div>
          <div className="column">
            <div id="mapBox" className="box" style={mapBoxStyle}></div>
            <div className="section has-text-centered is-hidden-desktop">
              <button className="button is-warning is-large" ref={modalTriggerRef2}>
                {intl.formatMessage({ id: "email_capture.request_early_access" })}
              </button>
              <EmailCaptureModal triggerRef={modalTriggerRef2} intl={intl} sourceDetail={'Map My Customers'} />
              </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default MapMyCustomers